import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'fixedData';

import { SvgDashboard, SvgDashboardExit, SvgHealth, SvgMenuHamburguer, SvgMenuPoint, SvgQuestions, SvgSetaRight, SvgSite, SvgUser, SvgUserDash } from 'components/SvgFile';

import Page_Users from 'components/Page/Users';
import Page_Users_Details from 'components/Page/Users/Details';

import Page_Questions from 'components/Page/Questions';
import Page_Questions_Details from 'components/Page/Questions/Details';

import Page_Site from 'components/Page/Site';
import Page_Site_Details from 'components/Page/Site/Details';

import Page_Questionnaire from 'components/Page/Questionnaire';
import Page_Config from 'components/Page/Config';
import Page_Health from 'components/Page/Health';
import Page_Health_Details from 'components/Page/Health/Details';

export default function Contents(props){

    const cookies = new Cookies();

    const [ status, setStatus ]           = useState(false);
    const [ idioma, setIdioma ]           = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        if(document.body.clientWidth <= 640){
            setStatus(false);
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 640){
                setStatus(false);
            }
        });
    }

    function CurrentPage(){
        window.history.pushState("", "", "/");
        switch(currentPage) {
            case "access_dash":
                return <Page_Users CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "access_dash_details":
                return <Page_Users_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "page_site":
                return <Page_Site CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "page_site_details":
                return <Page_Site_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "questions":
                return <Page_Questions CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "questions_details":
                return <Page_Questions_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "questionnaire":
                return <Page_Questionnaire CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "health":
                return <Page_Health CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "health_details":
                return <Page_Health_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            default:
                return <Page_Config CkickPage={ CkickPage } setLoading={ props.setLoading } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);
        RegisterListPag('idioma', setIdioma);

        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    useEffect(()=>{
        CurrentPage()
    }, [currentPage]);

    return(
        <div className="Contents">            
            <a href="https://sitemodelo2.fastevolution.com.br/" target="_blank">
                <div className="show_site">
                    <SvgSite color="#ffffff" className="icons_site" />
                </div>
            </a>

            <div className={ status ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">

                    <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                        <div className={ status ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                            <div className="">
                                {
                                    status ?
                                    <img alt="logotipo" src="./assets/logo.png" className="logotipo2" /> :
                                    <img alt="logotipo" src="./assets/logo.png" className="logotipo" />
                                }
                            </div>
                            <div className={ status ? "icon_menu icon_menu_active" : "icon_menu" }>
                                <SvgMenuHamburguer color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>

                    <div className={ status ? "show_data_top" : "show_data_top show_data_top_close" }>
                        <div className="opt_menu_dash">
                            <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("index"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboard color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Dashboard</div>
                                </div>
                            </div>

                            <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgUserDash color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Usuários</div>
                                </div>
                            </div>                         

                            <div className={ currentPage == "page_site" || currentPage == "page_site_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("page_site"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgSite color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Site</div>
                                </div>
                            </div>                           

                            <div className={ currentPage == "questions" || currentPage == "questions_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("questions"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgQuestions color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>FAQ</div>
                                </div>
                            </div>

                            <div className={ currentPage == "questionnaire" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("questionnaire"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgQuestions color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Questionário</div>
                                </div>
                            </div> 

                            <div className={ currentPage == "health" || currentPage == "health_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("health"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgHealth color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Consultas</div>
                                </div>
                            </div> 

                            <div className={ status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Sair</div>
                                </div>
                            </div>
                        </div>
                        <div className="div_profile">
                            <div className={ status ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                                <div className="div_img">
                                    {
                                        userFile !='' ?
                                        <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                        <SvgUser color="#FFFFFF" className="show_img_profile" />
                                    }
                                </div>
                                <div className={ status ? "name_user menu_close" : "name_user" }>
                                    { userName }
                                </div>
                                <div className={ status ? "open_profile menu_close" : "open_profile" }>
                                    <SvgMenuPoint color="#ffffff" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
        </div>
    )
}
