import { useState, useEffect } from "react";

import './Questionnaire.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Questionnaire } from "services/RegisterData";

export default function Page_Questionnaire(props){

    const [ userId, setUserId ]           = useState(GetUserData('id'));
    const [ idioma, setIdioma ]           = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ dataPage, setDataPage ]       = useState(GetDataPage("questionnaire"));

    const [ idPage, setIPage ] = useState(InitialData('id'));
    const [ title, setTitle ]  = useState(InitialData('title'));
    const [ text, setText ]    = useState(InitialData('text'));
    const [ list, setList ]    = useState(InitialData('contents'));

    function InitialData(type){
        const newData = dataPage.filter(item => item.idioma == idioma);
        return newData[0][type];
    }

    function NewQuestion(){
        const newData = [...list];
        newData.push({ "id": 0, "text": "" });
        setList(newData);
    }

    function HandleData(type, index, value){
        const newData = [...list];
        newData[index][type] = value;
        setList(newData);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...list];
            newData.splice(index, 1);
            setList(newData);
        }else {            
            SetModalData('Confirmation', { "origin": "questionnaire", "type" : "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Questionnaire(userId, idioma, idPage, title, text, list, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('questionnaire', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterListPag('idioma', setIdioma);
            UnRegisterDataPage('questionnaire', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'));
        setDataPage(GetDataPage("questionnaire"));

        setIPage(InitialData('id'));
        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setList(InitialData('contents'));
    }, [currentPage, dataPage, idioma]);
    
    return(
        <div className="Page_Questionnaire">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="title_page">Questionários</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>     

                <div className="type_data_fixed">
                    <div className="notification">
                        Os dados preenchidos abaixo só serão mostrados de acordo com o idioma selecionado
                    </div>
                    <div className="type_idioma">
                        <div className={ idioma == "pt_br" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'pt_br') } }>pt_br</div>
                        <div className={ idioma == "pt_pt" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'pt_pt') } }>pt_pt</div>
                        <div className={ idioma == "es" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'es') } }>es</div>
                        <div className={ idioma == "en" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'en') } }>en</div>
                    </div>
                </div>    

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Title</span>
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>   

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Perguntas</div>
                        <div className="new_block_text" onClick={ ()=>{ NewQuestion() } }>
                            Nova pergunta
                        </div>
                    </div>                
                    {
                        list.map((elem, index)=>{
                            return (                                                
                                <div className="list_input_data" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('text', index, e.target.value) } } value={ elem.text } maxLength="140" required />
                                        <span className="name_input">Pergunta</span>
                                    </div>
                                    <div className="div_input div_icon" onClick={ ()=>{ DeleteData(elem.id, index, elem.text) } }>
                                        <SvgDelete className="icons" color="#f00000" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}