import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config, hour } from "fixedData";

import TextareaAutosize from 'react-textarea-autosize';

import { SvgReturn, SvgSave } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Health } from "services/RegisterData";

export default function Page_Health_Details(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("health"));

    const [ idioma, setIdioma ]           = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ idPage, setIdPage ]           = useState(GetListPag('currentPageId'));

    const [ status, setStatus ]         = useState(InitialData('status'));
    const [ name, setName ]             = useState(InitialData('name'));
    const [ email, setEmail ]           = useState(InitialData('email'));
    const [ contact, setContact ]       = useState(InitialData('contact'));
    const [ date, setDate ]             = useState(InitialData('date'));
    const [ hourHealth, setHourHealth ] = useState(InitialData('hour'));
    const [ obs, setObs ]               = useState(InitialData('obs'));
    const [ question, setQuestion ]     = useState(InitialData('question'));

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type]
        }
        if(type == 'question'){
            return [];
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Health(userId, idPage, status, name, email, contact, date, hourHealth, obs, question, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'health');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('health', setDataPage);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('health', setDataPage);
            UnRegisterListPag('idioma', setIdioma);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("health"));
        setIdPage(GetListPag('currentPageId'));
        
        setStatus(InitialData('status'));
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setContact(InitialData('contact'));
        setDate(InitialData('date'));
        setHourHealth(InitialData('hour'));
        setObs(InitialData('obs'));
        setQuestion(InitialData('question'));
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Health_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            <SvgReturn className="icons" color="#324d6b" />
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <select onChange={ (e)=>{ setStatus(e.target.value) } } value={ status }>
                                <option value="0">Aguardando</option>
                                <option value="1">Finalizado</option>
                            </select>
                            <span className="name_input">Status</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="140" required />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setContact(e.target.value) } } value={ contact } maxLength="140" required />
                            <span className="name_input">Contato</span>
                        </div>
                        <div className="div_input">
                            <input type="date" className="date_health" onChange={ (e)=>{ setDate(e.target.value) } } value={ date } required />
                            <span className="name_input">Data da consulta</span>
                        </div>
                        <div className="div_input">
                            <select className="hour_health" onChange={ (e)=>{ setHourHealth(e.target.value) } } value={ hourHealth } required>
                                <option value="">#</option>
                                {
                                    hour.map((elem, index)=>{
                                        return(
                                            <option value={ elem } key={ index }>{ elem }</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Hora da consulta</span>
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor config={ config } value={ obs ? obs.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObs(newContent) } />
                        </div>
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Questionário respondido</div>
                    </div> 
                    {
                        question.map((elem, index)=>{
                            return(
                                <div className="list_input_data show_inf_questions" key={ index }>
                                    <div className="div_input space_div">
                                        <div className="data_text">{ elem.question }</div>
                                        <span className="name_input">Pergunta</span>
                                    </div>
                                    <div className="div_input space_div">  
                                        <div className="data_text">{ elem.response }</div>
                                        <span className="name_input">Resposta</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}