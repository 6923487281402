// Connection type
// const pageServidor = 'http://localhost/React_JS/fastevolution/site_modelo_2/dashboard/public/';
const pageServidor  = '.';
export const connectionPage = pageServidor;

// Cookies type
// const cookiesServidor        = 'localhost';
const cookiesServidor         = 'dashboardsitemodelo2.fastevolution.com.br/';
export const cookiesRegister = cookiesServidor;

// Cookies name
export const typeCookie       = 'siteModel_2_dash';
export const typeCookiePassw  = 'siteModel_2_passw';
export const typeCookieEmail  = 'siteModel_2_email';
export const typeCookiePage   = 'siteModel_2_page';
export const typeCookiePageId = 'siteModel_2_id';
export const typeCookieIdioma = 'siteModel_2_idioma';

// opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,    
    buttons: ['fontsize', 'bold', 'italic', 'underline', 'align'],
    // buttons: ['fontsize', 'bold', 'italic', 'underline', 'brush', 'hr', 'image', 'link', 'paragraph', 'ul', 'ol', 'source'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "Digite o texto aqui...",
    toolbarAdaptive: false
}

// week
export const week = [
    {
        "value": "monday",
        "label": "Segunda-feira"
    },
    {
        "value": "tuesday",
        "label": "Terça-feira"
    },
    {
        "value": "wednesday",
        "label": "Quarta-feira"
    },
    {
        "value": "thursday",
        "label": "Quinta-feira"
    },
    {
        "value": "friday",
        "label": "Sexta-feira"
    },
    {
        "value": "saturday",
        "label": "Sábado"
    }    
]
// hour
export const hour = [
    "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00"
]

// idioma
export const idioma = {
    "pt_br": {
        
    }
}