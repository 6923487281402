import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgReturn, SvgSave, SvgSetaRight, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_PageSite } from "services/RegisterData";

export default function Page_Site_Details(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("page_site"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ type, setType ]           = useState(InitialData('type'));
    const [ titlePage, setTitlePage ] = useState(InitialData('title_page'));

    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ color, setColor ]         = useState(InitialData('color'));
    const [ btn, setBtn ]             = useState(InitialData('show_btn'));
    const [ nameBtn, setNameBtn ]     = useState(InitialData('name_btn'));
    const [ subtitle, setSubtitle ]   = useState(InitialData('subtitle'));
    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ]         = useState(InitialData('video'));
    const [ text, setText ]           = useState(InitialData('text'));
    const [ fileAlign, setFileAlign ] = useState(InitialData('file_align'));
    const [ file, setFile ]           = useState(InitialData('file'));
    const [ status, setStatus ]       = useState(false);

    // card
    const [ cardData, setCardData ] = useState(InitialData('card_data'));
    // card icons
    const [ cardIcons, setCardIcons ] = useState(InitialData('card_icons'));
    // arrow data
    const [ arrowData, setArrowData ] = useState(InitialData('arrow_data'));

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == "card_data" || type == "card_icons" || type == "arrow_data"){
                return newData['contents'];
            }
            return newData[type];
        }
        if(type == "file_align"){
            return "left";
        }
        if(type == "show_btn"){
            return "Não";
        }
        if(type == "type_video"){
            return "youtube";
        }
        if(type == "color"){
            return "#F5F5F5";
        }
        if(type == "card_data"){
            return [
                {
                    "id": 0,
                    "title": "",
                    "color": "",
                    "btn": "",
                    "file": "",
                    "status": false,
                    "text": ""
                },
                {
                    "id": 0,
                    "title": "",
                    "color": "",
                    "btn": "",
                    "file": "",
                    "status": false,
                    "text": ""
                }
            ];
        }
        if(type == "card_icons"){
            return [
                {
                    "id": 0,
                    "file": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "file": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "file": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "file": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "file": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "file": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "file": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "file": "",
                    "text": ""
                }
            ];
        }
        if(type == "arrow_data"){
            return [
                {
                    "id": 0,
                    "title": "",
                    "color": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "title": "",
                    "color": "",
                    "text": ""
                },
                {
                    "id": 0,
                    "title": "",
                    "color": "",
                    "text": ""
                }
            ];
        }
        return '';
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function HandleDataCard(type, index, value){
        const newData = [...cardData];
        if(type == 'file'){
            if(value){
                newData[index]['file']   = value;
                newData[index]['status'] = true;

            }else {
                newData[index]['file']   = "";
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setCardData(newData);
    }

    function HandleDataCardIcons(type, index, value){
        const newData = [...cardIcons];
        if(type == 'file'){
            if(value){
                newData[index]['file']   = value;
                newData[index]['status'] = true;

            }else {
                newData[index]['file']   = "";
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setCardIcons(newData);
    }

    function HandleDataArrow(type, index, value){
        const newData = [...arrowData];
        newData[index][type] = value;
        setArrowData(newData);
    }

    function CurrentPage(){
        switch (type) {
            case "Home":
                return(
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                                <span className="name_input">Título</span>
                            </div>
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ setSubtitle(e.target.value) } } value={ subtitle } maxLength="140" required />
                                <span className="name_input">Subtítulo</span>
                            </div>
                        </div>
                        <div className="list_input_data">
                            <div className="div_input">
                                <select className="type_video" onChange={ (e)=>{ setTypeVideo(e.target.value) } } value={ typeVideo } required>
                                    <option value="vimeo">Vímeo</option>
                                    <option value="youtube">Youtube</option>
                                </select>
                                <span className="name_input">Tipo de vídeo</span>
                            </div>
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ setVideo(e.target.value) } } value={ video } required />
                                <span className="name_input">Link do vídeo</span>
                            </div>
                            {
                                video == '' ? null : 
                                <div className="div_input space_top" onClick={ ()=>{ OpenFile(typeVideo, video) } }>
                                    {
                                        typeVideo == "vimeo" ?
                                        <SvgVimeo color="#324d6b" className="icons"/>
                                        :
                                        <SvgYoutube color="#324d6b" className="icons"/>
                                    }
                                </div>
                            }
                            <span className="div_input div_add_img">
                                <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                        <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    file !='' ?
                                    <>
                                        <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                            <SvgDelete className="icons" color="#ffffff" />
                                        </div>
                                        {
                                            status == true ?
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div> :
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + file) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div>
                                        }
                                    </>
                                    : null
                                }
                            </span>
                        </div>
                        <div className="list_input_data">   
                            <div className="div_input space_div">
                                <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                            </div>
                        </div>
                    </>
                )

            case "Texto com imagem": case "Texto": case "Texto com título em destaque":
                return(
                    <>
                        <div className="list_input_data">
                            <div className="div_input">
                                <select className="" onChange={ (e)=>{ setBtn(e.target.value) } } value={ btn } required>
                                    <option value="Sim">Sim</option>
                                    <option value="Não">Não</option>
                                </select>
                                <span className="name_input">Botão</span>
                            </div>
                            {
                                btn == 'Não' ? null :
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setNameBtn(e.target.value) } } value={ nameBtn } maxLength="40" />
                                    <span className="name_input">Nome do botão</span>
                                </div>
                            }
                            <div className="div_input">
                                <select className="" onChange={ (e)=>{ setFileAlign(e.target.value) } } value={ fileAlign } required>
                                    <option value="left">{ type == "Texto com imagem" ? "Imagem" : "Título" } a esquerda</option>
                                    <option value="right">{ type == "Texto com imagem" ? "Imagem" : "Título" } a direita</option>
                                    {
                                        type == "Texto com imagem" ? null : <option value="center">Título centralizado</option>
                                    }
                                    {
                                        type != "Texto com imagem" ? null : 
                                        <>
                                            <option value="bg_left">Imagem de fundo com texto a esquerda</option>
                                            <option value="bg_right">Imagem de fundo com texto a direita</option>
                                        </>
                                    }
                                </select>
                                <span className="name_input">Posição { type == "Texto com imagem" ? "Imagem" : "Título" }</span>
                            </div>
                            <div className="div_input list_color">
                                <div className="">Escola a cor de fundo</div>
                                <div className="div_color" onClick={ ()=>{ setColor('not') } }>
                                    Transparente 
                                    { color == "not" ? " - Selecionada" : "" }
                                </div>
                                <div className="div_color" style={ { background: "#F5F5F5", color: "#6B7D5C" } } onClick={ ()=>{ setColor('#F5F5F5') } }>
                                    { color == "#F5F5F5" ? "Selecionada" : "" }
                                </div>
                                <div className="div_color" style={ { background: "#E7E3CE", color: "#6B7D5C" } } onClick={ ()=>{ setColor('#E7E3CE') } }>
                                    { color == "#E7E3CE" ? "Selecionada" : "" }
                                </div>
                                <div className="div_color" style={ { background: "#6B7D5C", color: "#F5F5F5" } } onClick={ ()=>{ setColor('#6B7D5C') } }>
                                    { color == "#6B7D5C" ? "Selecionada" : "" }
                                </div>
                            </div>
                            {
                                type != "Texto com imagem" ? null : 
                                <span className="div_input div_add_img">
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                        </label>
                                    </div>
                                    {
                                        file !='' ?
                                        <>
                                            <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                            {
                                                status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + file) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            }
                                        </>
                                        : null
                                    }
                                </span>
                            }
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                                <span className="name_input">Título</span>
                            </div>
                        </div>
                        <div className="list_input_data">   
                            <div className="div_input space_div">
                                <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                            </div>
                        </div>
                    </>
                )

            case "Card com imagem":
                return(
                    <div className="list_input_data show_data_card_img">
                        {
                            cardData.map((elem, index)=>{
                                return(
                                    <div className="div_input space_div show_data_text div_card_img" key={ index }>
                                        <span className="name_input">Card { index + 1 }</span>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleDataCard('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                            <span className="name_input">Título</span>
                                        </div>
                                        {/* 
                                        <div className="div_btn_img">
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataCard('color', index, e.target.value) } } value={ elem.color } maxLength="140" />
                                                <span className="name_input">Cor</span>
                                            </div>
                                            <div className="div_input div_color" style={ { background: elem.color } } />
                                        </div> 
                                        */}
                                        <div className="div_btn_img">
                                            <div className="div_input">
                                                <input type="text" onChange={ (e)=>{ HandleDataCard('btn', index, e.target.value) } } value={ elem.btn } maxLength="40" />
                                                <span className="name_input">Nome botão</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { elem.status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataCard('file', index, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    elem.file !='' ?
                                                    <>
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleDataCard('file', index, ""); } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div>
                                                        {
                                                            elem.status == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + elem.file) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                        }
                                                    </>
                                                    : null
                                                }
                                            </span>
                                        </div>
                                        <div className="div_input space_div">
                                            <JoditEditor config={ config } value={ elem.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleDataCard('text', index, newContent) } />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )

            case "Card com icones":
                return(
                    <div className="list_input_data show_data_card_icons">
                        {
                            cardIcons.map((elem, index)=>{
                                return(
                                    <div className="div_input space_div show_data_text div_card_icons" key={ index }>
                                        <span className="name_input">Card com ícone { index + 1 }</span>
                                        {/* 
                                        <div className="div_btn_img">
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataCard('color', index, e.target.value) } } value={ elem.color } maxLength="140" />
                                                <span className="name_input">Cor</span>
                                            </div>
                                            <div className="div_input div_color" style={ { background: elem.color } } />
                                        </div> 
                                        */}
                                        <span className="div_input div_add_img">
                                            <div className={ elem.status == true ? "new_file_add new_file_add_active space_div" : "new_file_add space_div" }>
                                                <label>
                                                    { elem.status == true ? "Ícone adicionado" : "Adicionar ícone" }
                                                    <input type="file" onChange={ (e)=>{ HandleDataCardIcons('file', index, e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                            {
                                                elem.file !='' ?
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleDataCardIcons('file', index, ""); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    {
                                                        elem.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + elem.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </span>
                                        <div className="div_input space_div">
                                            <JoditEditor config={ config } value={ elem.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleDataCardIcons('text', index, newContent) } />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )

            case "Seta":
                return(
                    <div className="list_input_data show_data_arrow">
                        {
                            arrowData.map((elem, index)=>{
                                return(
                                    <div className="div_input space_div show_data_text div_arrow" key={ index }>
                                        <span className="name_input">Seta { index + 1 }</span>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleDataArrow('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                            <span className="name_input">Título</span>
                                        </div>
                                        {/* 
                                        <div className="div_btn_img">
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataCard('color', index, e.target.value) } } value={ elem.color } maxLength="140" />
                                                <span className="name_input">Cor</span>
                                            </div>
                                            <div className="div_input div_color" style={ { background: elem.color } } />
                                        </div> 
                                        */}
                                        <div className="div_input space_div">
                                            <JoditEditor config={ config } value={ elem.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleDataArrow('text', index, newContent) } />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_PageSite(idioma, userId, idPage, btn, nameBtn, color, type, titlePage, title, subtitle, typeVideo, video, text, fileAlign, file, cardData, cardIcons, arrowData, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'page_site');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('page_site', setDataPage);

        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('page_site', setDataPage);

            UnRegisterListPag('idioma', setIdioma);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("page_site"));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData('type'));
        setTitlePage(InitialData('title_page'));

        setTitle(InitialData('title'));
        setColor(InitialData('color'));
        setBtn(InitialData('show_btn'));
        setNameBtn(InitialData('name_btn'));

        setSubtitle(InitialData('subtitle'));
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setText(InitialData('text'));
        setFileAlign(InitialData('file_align'));
        setFile(InitialData('file'));
        setStatus(false);

        setCardData(InitialData('card_data'));
        setCardIcons(InitialData('card_icons'));
        setArrowData(InitialData('arrow_data'));
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Site_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgReturn className="icons" color="#324d6b" />
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        {
                            idPage != 0 ? null :
                            <div className="div_input">
                                <select className="" onChange={ (e)=>{ setType(e.target.value) } } required>
                                    <option value="">#</option>
                                    <option value="Home">Home</option>
                                    <option value="Texto">Texto</option>
                                    <option value="Texto com imagem">Texto com imagem</option>
                                    <option value="Texto com título em destaque">Texto com título em destaque</option>
                                    <option value="Card com imagem">Card com imagem</option>
                                    <option value="Card com icones">Card com icones</option>
                                    <option value="Seta">Seta</option>
                                </select>
                                <span className="name_input">Tipo de bloco</span>
                            </div>
                        }
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitlePage(e.target.value) } } value={ titlePage } maxLength="140" required />
                            <span className="name_input">Título da bloco</span>
                        </div>
                    </div>
                </div>


                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados que serão mostrado na página</div>
                    </div>
                    {
                        type == "" ? <div className="list_input_data no_data">É necessário selecionar um tipo de bloco</div> : 
                        CurrentPage()
                    }
                </div>
            </form>
        </div>
    )
}