import { useState, useEffect } from "react";

import './Health.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSearch, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_AltPosition } from "services/RegisterData";

export default function Page_Health(props){

    const [ search, setSearch ]           = useState('');
    const [ userId, setUserId ]           = useState(GetUserData('id'));
    const [ idioma, setIdioma ]           = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ dataPage, setDataPage ]       = useState(GetDataPage('health'));

    const [ itensPage, setItensPage ]       = useState(50);
    const [ showPage, setShowPage ]         = useState(0);
    const [ showPageData, setShowPageData ] = useState(dataPage);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newData = GetDataPage('health').filter(item => item.idioma == idioma);
        const newList = [];
        if(value){
            newData.forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.contact.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.date.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.obs.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(newData);
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "health", "type" : "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'health_details')
        SetListPag('currentPageId', idPage);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPage', setCurrentPage);
        RegisterDataPage('health', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterListPag('idioma', setIdioma);
            UnRegisterListPag('currentPage', setCurrentPage);
            UnRegisterDataPage('health', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('health'));
        setShowPageData(dataPage);
    }, [currentPage, dataPage]);

    return(
        <div className="Page_Health">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Consultas</div>
                <div className="list_opt_alt_page">
                    <div className="div_add_new_data" onClick={ ()=>{ PageClick(0) } }>
                        <div className="new_data_add">
                            <SvgAddNewData color="#ffffff" className="icons" />
                        </div>
                        <div className="name_btn_add">consulta</div>
                    </div>
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th className="">Nome</th>
                            <th className="td_email">E-mail</th>
                            <th className="td_status" width="80px">status</th>
                            <th className="td_hour" width="80px">Hora</th>
                            <th className="td_date" width="80px">Data</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td className="td_email">{ elem.email }</td>
                                        <td className="td_status">{ elem.status == 0 ? "Aguardando" : "Finalizado" }</td>
                                        <td className="td_hour">{ elem.hour }</td>
                                        <td className="td_date">{ elem.date_br }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div data-tooltip-id={ "user_delete_" + elem.id } data-tooltip-content="Deletar consulta" data-tooltip-place="top" onClick={ ()=>{ DeleteData(elem.id, elem.question) } } title="Deletar consulta">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "user_delete_" + elem.id } />
                                                <div data-tooltip-id={ "user_edit_" + elem.id } data-tooltip-content="Editar consulta" data-tooltip-place="top" onClick={ ()=>{ PageClick(elem.id) } } title="Editar consulta">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "user_edit_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}