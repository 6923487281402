import { cookiesRegister, typeCookieIdioma, typeCookiePage, typeCookiePageId } from 'fixedData';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

let ListPag = {
    "page"          : 'login',
    "currentPage"   : 'index',
    "currentPageId" : '',
    "idioma"        : 'pt_pt'
};

let NotifyListPag = {
    "page"          : [],
    "currentPage"   : [],
    "currentPageId" : [],
    "idNewRegister" : [],
    "idioma"        : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    // data atual
    const hoje = new Date();

    const tresDiasAFrente = new Date();
    tresDiasAFrente.setDate(hoje.getDate() + 3);

    if(key == 'currentPageId'){
        if(value == 'remuve'){
            cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
        }else {
            cookies.set(typeCookiePageId, value, { path: '/', expires: tresDiasAFrente }, cookiesRegister);
        }
    }

    if(key == 'idioma'){
        cookies.set(typeCookieIdioma, value, { path: '/', expires: tresDiasAFrente }, cookiesRegister);
    }

    if(key == 'currentPage'){
        cookies.set(typeCookiePage, value, { path: '/', expires: tresDiasAFrente }, cookiesRegister);
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}

export function UnRegisterListPag(key, callback){
    if(NotifyListPag[key].length > 0){
        NotifyListPag[key] = NotifyListPag[key].filter((item) => {
            return item !== callback;
        });
    }
}